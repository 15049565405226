"use client";

import { Fragment, HTMLAttributes, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { LoaderCircle } from "lucide-react";
import { useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@components/common/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@components/common/form";
import { Input } from "@components/common/input";
import { Textarea } from "@components/common/textarea";
import { ToastAction } from "@components/common/toast";
import { useToast } from "@hooks/use-toast";
import { sendNotification } from "@services/feedback";
import { Account } from "@types";

const Contact = ({
  account,
  subject,
  className,
  onSubmitted,
}: {
  account?: Account;
  subject?: string;
  className?: HTMLAttributes<HTMLElement>["className"];
  onSubmitted?: () => void;
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const t = useTranslations("common.contact");
  const tToast = useTranslations("common.toast");
  const { toast } = useToast();

  const contactSchema = z.object({
    email: z.string({ message: t("emailRequired") }).email(t("emailInvalid")),
    subject: z
      .string({ message: t("subjectRequired") })
      .min(1, { message: t("subjectRequired") }),
    message: z
      .string({ message: t("messageRequired") })
      .min(1, { message: t("messageRequired") }),
  });

  const form = useForm<z.infer<typeof contactSchema>>({
    resolver: zodResolver(contactSchema),
    defaultValues: {
      email: account?.email ?? "",
      subject: subject ?? "",
      message: "",
    },
  });

  const showError = (values: z.infer<typeof contactSchema>): void => {
    toast({
      title: tToast("errorTitle"),
      description: tToast("errorDescription"),
      variant: "destructive",
      action: (
        <ToastAction
          altText={tToast("tryAgain")}
          onClick={() => onSubmit(values)}
        >
          {tToast("tryAgain")}
        </ToastAction>
      ),
    });
  };

  const onSubmit = (values: z.infer<typeof contactSchema>): void => {
    setIsLoading(true);
    sendNotification({
      email: values.email,
      subject: values.subject,
      message: values.message,
      accountId: account?.id,
    })
      .then(({ error }) => {
        if (error) {
          showError(values);
        } else {
          onSubmitted && onSubmitted();
          setIsSubmitted(true);
        }
      })
      .catch(() => {
        showError(values);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      {!isSubmitted && (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className={classNames("space-y-4", className)}
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("emailLabel")}</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder={t("emailPlaceholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="subject"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("subjectLabel")}</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder={t("subjectPlaceholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("messageLabel")}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t("messagePlaceholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className={classNames("w-full", { "md:w-fit": !account })}
              disabled={isLoading}
            >
              {isLoading && <LoaderCircle className="mr-1 animate-spin" />}
              {t("submit")}
            </Button>
          </form>
        </Form>
      )}
      {isSubmitted && (
        <p className="text-center font-medium">{t("submitted")}</p>
      )}
    </Fragment>
  );
};

export default Contact;
