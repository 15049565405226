import { produce } from "immer";
import { StoreApi } from "zustand";

import { getMapboxRoute } from "@services/mapbox";
import type { MapCoordinates, MapRoute, Tracking } from "@types";
import { getMapRoute } from "@utils";

export interface MapState {
  mapSlice: {
    route: MapRoute;
    updateRoute: (trackingRoute: Tracking | undefined) => void;
  };
}

export type MapSlice = (set: StoreApi<MapState>["setState"]) => MapState;

export const createMapSlice: MapSlice = (set) => ({
  mapSlice: {
    route: [],
    updateRoute: async (tracking: Tracking | undefined): Promise<void> => {
      let route: MapRoute = [];

      if (tracking) {
        const trackingRoute: Array<MapCoordinates> = getMapRoute(tracking);

        if (trackingRoute.length >= 2) {
          try {
            const { direction, error } = await getMapboxRoute(trackingRoute);

            if (!error && direction?.routes[0]) {
              route = direction.routes[0].geometry.coordinates;
            }
          } catch {
            // eslint-disable-next-line no-empty
          }
        }
      }

      set(
        produce<MapState>((state) => {
          state.mapSlice.route = route;
        }),
      );
    },
  },
});
