import { Device } from "@types";

export interface DeviceState {
  deviceSlice: {
    isMobile: boolean;
    device: Device;
  };
}

export type DeviceSlice = (device: Device) => DeviceState;

export const createDeviceSlice: DeviceSlice = (device) => ({
  deviceSlice: {
    isMobile: device.type === "mobile",
    device,
  },
});
