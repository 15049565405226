"use client";

import { ComponentProps } from "react";

import classNames from "classnames";
import { useTranslations } from "next-intl";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/common/select";
import { ToastAction } from "@components/common/toast";
import { useStore } from "@hooks/use-store";
import { useToast } from "@hooks/use-toast";
import { MapType } from "@types";

const MapSelector = ({ className }: ComponentProps<"div">): JSX.Element => {
  const t = useTranslations("common");
  const { toast } = useToast();
  const { mapType, changeMapType } = useStore((state) => ({
    mapType: state.acountSlice.account.settings.mapType,
    changeMapType: state.acountSlice.changeMapType,
  }));

  const changeMapTypeHandler = (value: MapType): void =>
    changeMapType(value, ({ error }) => {
      error &&
        toast({
          title: t("toast.errorTitle"),
          description: t("toast.errorDescription"),
          variant: "destructive",
          action: (
            <ToastAction
              altText={t("toast.tryAgain")}
              onClick={() => changeMapTypeHandler(value)}
            >
              {t("toast.tryAgain")}
            </ToastAction>
          ),
        });
    });

  return (
    <Select defaultValue={mapType} onValueChange={changeMapTypeHandler}>
      <SelectTrigger className={classNames("w-[200px]", className)}>
        <SelectValue placeholder={t("mapSelector.placeholder")} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="navigation">
          {t("mapSelector.navigation")}
        </SelectItem>
        <SelectItem value="streets">{t("mapSelector.streets")}</SelectItem>
        <SelectItem value="outdoors">{t("mapSelector.outdoors")}</SelectItem>
        <SelectItem value="satellite">{t("mapSelector.satellite")}</SelectItem>
        <SelectItem value="monochrome">
          {t("mapSelector.monochrome")}
        </SelectItem>
      </SelectContent>
    </Select>
  );
};

export default MapSelector;
