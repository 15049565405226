"use client";

import { useEffect, useState } from "react";

import { Moon, Sun } from "lucide-react";
import { useTranslations } from "next-intl";
import { useTheme } from "next-themes";

import { Button } from "@components/common/button";
import { Skeleton } from "@components/common/skeleton";
import Tooltip, { TooltipContent, TooltipTrigger } from "@components/tooltip";
import { useStore } from "@hooks/use-store";

const ThemeSelector = (): JSX.Element => {
  const t = useTranslations("common.themeSelector");
  const { isMobile } = useStore((state) => ({
    isMobile: state.deviceSlice.isMobile,
  }));
  const { setTheme, theme } = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const toggleTheme = (): void => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return isLoading ? (
    <Skeleton className="h-10 min-h-10 w-10 min-w-10" />
  ) : (
    <Tooltip isMobile={isMobile}>
      <TooltipTrigger asChild>
        <Button
          className="min-h-10 min-w-10"
          variant={"outline"}
          size={"icon"}
          onClick={toggleTheme}
        >
          {theme === "light" ? (
            <Moon className="h-4 w-4" />
          ) : (
            <Sun className="h-4 w-4" />
          )}
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>{theme === "light" ? t("light") : t("dark")}</p>
      </TooltipContent>
    </Tooltip>
  );
};

export default ThemeSelector;
