"use client";

import { HTMLAttributes } from "react";

import classNames from "classnames";
import { ChevronRightIcon } from "lucide-react";
import Link from "next/link";
import { DefaultSession } from "next-auth";
import { useTranslations } from "next-intl";

import { Avatar, AvatarFallback, AvatarImage } from "@components/common/avatar";
import { Button } from "@components/common/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/common/popover";
import { Separator } from "@components/common/separator";
import Settings from "@components/header/app/header-settings";
import Tooltip, { TooltipContent, TooltipTrigger } from "@components/tooltip";
import { useStore } from "@hooks/use-store";
import { getMenuURL, getNameDigits } from "@utils";

const HeaderAccount = ({
  user,
  isMobile,
  onOpenChange,
  size = "medium",
  side = "right",
  className,
}: {
  user: DefaultSession["user"];
  isMobile: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  size?: "medium" | "large";
  side?: "bottom" | "right" | "top";
  className?: HTMLAttributes<HTMLElement>["className"];
}): JSX.Element => {
  const t = useTranslations("common.header");
  const { quantity: subscriptionQuantity, onOpenMenuAccount } = useStore(
    (state) => ({
      quantity: state.acountSlice.account.subscription?.quantity,
      onOpenMenuAccount: state.navigationSlice.onOpenMenuAccount,
    }),
  );

  return (
    <Popover onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size={size === "large" ? "default" : "icon"}
          className={classNames(
            {
              "flex h-12 items-center justify-center gap-2": size === "large",
            },
            className,
          )}
        >
          <Avatar>
            {user?.image && (
              <AvatarImage src={user.image} alt={t("avatarImage")} />
            )}
            <AvatarFallback>
              {getNameDigits(user?.name || user?.email || "")}
            </AvatarFallback>
          </Avatar>
          {size === "large" && <span className="text-sm">{t("account")}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        side={side}
        sideOffset={side === "right" ? 25 : 10}
        className="mb-2 flex max-w-60 flex-col gap-4"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <Tooltip isMobile={isMobile}>
          <TooltipTrigger asChild>
            <div className="mb-1 flex items-center justify-center gap-2">
              <Avatar>
                {user?.image && (
                  <AvatarImage src={user.image} alt={t("avatarImage")} />
                )}
                <AvatarFallback>
                  {getNameDigits(user?.name || user?.email || "")}
                </AvatarFallback>
              </Avatar>
              <p className="line-clamp-2 text-ellipsis text-sm">
                {user?.name || user?.email || ""}
              </p>
            </div>
          </TooltipTrigger>
          <TooltipContent sideOffset={20}>
            <p>{user?.name || user?.email || ""}</p>
          </TooltipContent>
        </Tooltip>
        <div className="flex flex-col gap-2">
          <Button
            variant={"outline"}
            className="flex justify-center gap-2"
            asChild
          >
            <Link href={getMenuURL("subscription")}>
              <span className="flex-1 text-center">
                {t("subscription")}
                {subscriptionQuantity
                  ? ` (${subscriptionQuantity})`
                  : undefined}
              </span>
              <ChevronRightIcon className="w-4" />
            </Link>
          </Button>
          <Button
            variant={"outline"}
            className="flex justify-center gap-2"
            onClick={() => onOpenMenuAccount(true)}
          >
            <span className="flex-1 text-center">{t("contact")}</span>
            <ChevronRightIcon className="w-4" />
          </Button>
        </div>
        <Separator />
        <Settings />
      </PopoverContent>
    </Popover>
  );
};

export default HeaderAccount;
