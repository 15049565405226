import { produce } from "immer";
import { StoreApi } from "zustand";

import { getAccountRequest, updateAccountSettings } from "@services/account";
import { Account, MapType } from "@types";

import { StoreStateCallback } from ".";

export interface AccountState {
  acountSlice: {
    account: Account;
    changeMapType: (mapType: MapType, callBack?: StoreStateCallback) => void;
    updateAccount: () => Promise<void>;
  };
}

export type AccountSlice = (
  set: StoreApi<AccountState>["setState"],
  get: StoreApi<AccountState>["getState"],
  account: Account,
) => AccountState;

export const createAccountSlice: AccountSlice = (set, get, account) => ({
  acountSlice: {
    account: account,
    changeMapType: async (
      mapType: MapType,
      callBack?: StoreStateCallback,
    ): Promise<void> => {
      const account: Account = get().acountSlice.account;
      const { settings, error } = await updateAccountSettings({
        id: account.id,
        settings: { ...account.settings, mapType: mapType },
      });

      if (!error) {
        set(
          produce<AccountState>((state) => {
            state.acountSlice.account.settings.mapType = settings.mapType;
          }),
        );
      }
      callBack && callBack({ error });
    },
    updateAccount: async (): Promise<void> => {
      const { account, error } = await getAccountRequest();
      if (!error) {
        set(
          produce<AccountState>((state) => {
            state.acountSlice.account = account;
          }),
        );
      }
    },
  },
});
