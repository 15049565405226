import { produce } from "immer";
import { StoreApi } from "zustand";

import type { Device, Menu } from "@types";

export interface NavigationState {
  navigationSlice: {
    selectedMenu: Menu | undefined;
    changeMenu: (menu?: Menu) => void;
    isMenuCollapsed: boolean;
    collapseMenu: (isCollapsed: boolean) => void;
    isMenuAccountOpen: boolean;
    menuAccountSubject: string | undefined;
    onOpenMenuAccount: (isOpen: boolean, subject?: string) => void;
  };
}

export type NavigationSlice = (
  set: StoreApi<NavigationState>["setState"],
  device: Device,
  selectedMenu: Menu | undefined,
) => NavigationState;

export const createNavigationSlice: NavigationSlice = (
  set,
  device,
  selectedMenu,
) => ({
  navigationSlice: {
    selectedMenu: getSelectedMenu(selectedMenu, device),
    changeMenu: (menu: Menu | undefined): void => {
      set(
        produce<NavigationState>((state) => {
          state.navigationSlice.selectedMenu = menu;
        }),
      );
    },
    isMenuCollapsed: true,
    collapseMenu: (isMenuCollapsed: boolean): void => {
      set(
        produce<NavigationState>((state) => {
          state.navigationSlice.isMenuCollapsed = isMenuCollapsed;
        }),
      );
    },
    isMenuAccountOpen: false,
    onOpenMenuAccount: (isOpen: boolean, subject?: string): void => {
      set(
        produce<NavigationState>((state) => {
          state.navigationSlice.isMenuAccountOpen = isOpen;
          state.navigationSlice.menuAccountSubject = subject;

          if (!isOpen) {
            state.navigationSlice.menuAccountSubject = undefined;
          }
        }),
      );
    },
    menuAccountSubject: undefined,
  },
});

const getSelectedMenu = (
  selectedMenu: Menu | undefined,
  device: Device,
): Menu | undefined =>
  selectedMenu || (device.type === "mobile" ? undefined : "map-trackers");
