import { StoreApi, createStore } from "zustand";

import type { Account, Device, Menu, Trackings } from "@types";

import { AccountState, createAccountSlice } from "./account";
import { createDeviceSlice, DeviceState } from "./device";
import { createMapSlice, MapState } from "./map";
import { NavigationState, createNavigationSlice } from "./navigation";
import { createTrackerSlice, TrackerState } from "./tracker";

export type StoreState = AccountState &
  NavigationState &
  TrackerState &
  MapState &
  DeviceState;

export type StoreStateCallback = ({ error }: { error?: boolean }) => void;

export const createAppStore = (
  account: Account,
  trackings: Trackings,
  device: Device,
  selectedMenu: Menu | undefined,
): StoreApi<StoreState> =>
  createStore<StoreState>((set, get) => ({
    ...createAccountSlice(set, get, account),
    ...createNavigationSlice(set, device, selectedMenu),
    ...createTrackerSlice(set, get, trackings),
    ...createMapSlice(set),
    ...createDeviceSlice(device),
  }));
