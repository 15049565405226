import LanguageSelector from "./language-selector";
import MapSelector from "./map-selector";
import SignOut from "./sign-out";
import ThemeSelector from "./theme-selector";

const HeaderSettings = (): JSX.Element => (
  <div className="flex flex-col space-y-4">
    <div className="flex space-x-4">
      <MapSelector className="w-full md:w-[200px]" />
      <ThemeSelector />
    </div>
    <LanguageSelector />
    <SignOut />
  </div>
);

export default HeaderSettings;
