"use client";

import { createContext, ReactNode, useState } from "react";

import { StoreApi } from "zustand";

import { StoreState, createAppStore } from "@stores";
import type { Account, Device, Menu, Trackings } from "@types";

export const StoreContext = createContext<StoreApi<StoreState> | null>(null);

const StoreProvider = ({
  account,
  trackings,
  device,
  selectedMenu,
  children,
}: {
  account: Account;
  trackings: Trackings;
  device: Device;
  selectedMenu: Menu | undefined;
  children: ReactNode;
}): JSX.Element => {
  const [store] = useState(() =>
    createAppStore(account, trackings, device, selectedMenu),
  );
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;
