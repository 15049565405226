"use client";

import { useTranslations } from "next-intl";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@components/common/select";
import { useLocale } from "@hooks/use-locale";
import { useNavigation } from "@hooks/use-navigation";
import { Locale } from "@types";

const LanguageSelector = (): JSX.Element => {
  const t = useTranslations("common.languageSelector");
  const { useRouter, usePathname } = useNavigation();
  const { push, refresh } = useRouter();
  const pathname = usePathname();
  const locale = useLocale();

  return (
    <Select
      defaultValue={locale}
      onValueChange={(locale: Locale) => {
        push(pathname, { locale });
        refresh();
      }}
    >
      <SelectTrigger>
        <SelectValue placeholder={t("placeholder")} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="en">{t("en")}</SelectItem>
        <SelectItem value="pt">{t("pt")}</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default LanguageSelector;
