"use client";

import { useContext } from "react";

import { useStore as useZustandStore } from "zustand";
import { useShallow } from "zustand/react/shallow";

import { StoreContext } from "@providers/store-provider";
import type { StoreState } from "@stores";

export const useStore = <T>(selector: (state: StoreState) => T): T => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error("Missing StoreProvider");
  }
  return useZustandStore(store, useShallow(selector));
};
