"use client";

import { useTranslations } from "next-intl";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@components/common/dialog";
import { ScrollArea } from "@components/common/scroll-area";
import Contact from "@components/contact";
import { useStore } from "@hooks/use-store";
import { useToast } from "@hooks/use-toast";

const HeaderContact = (): JSX.Element => {
  const t = useTranslations("common.contact");
  const { account, isMenuAccountOpen, onOpenMenuAccount, menuAccountSubject } =
    useStore((state) => ({
      account: state.acountSlice.account,
      isMenuAccountOpen: state.navigationSlice.isMenuAccountOpen,
      onOpenMenuAccount: state.navigationSlice.onOpenMenuAccount,
      menuAccountSubject: state.navigationSlice.menuAccountSubject,
    }));
  const { toast } = useToast();

  const onSubmitted = (): void => {
    onOpenMenuAccount(false);
    toast({
      title: t("title"),
      description: t("submitted"),
      variant: "success",
    });
  };

  return (
    <Dialog
      open={isMenuAccountOpen}
      onOpenChange={(isOpen) => onOpenMenuAccount(isOpen)}
    >
      <DialogContent
        className="flex max-h-[80vh] flex-col"
        closeText={t("close")}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>{t("title")}</DialogTitle>
          <DialogDescription>{t("description")}</DialogDescription>
        </DialogHeader>
        <ScrollArea
          className="-m-6 flex-1 overflow-auto"
          viewportProps={{
            className: "p-6",
          }}
        >
          <Contact
            account={account}
            onSubmitted={onSubmitted}
            subject={menuAccountSubject}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default HeaderContact;
