import { produce } from "immer";
import { StoreApi } from "zustand";

import { getTrackings } from "@services/tracker";
import { StoreStateCallback } from "@stores";
import type { Pagination, Tracking, Trackings } from "@types";

export interface TrackerState {
  trackerSlice: {
    selectedTracking: Tracking | undefined;
    isLoadingTrackings: boolean;
    trackings: Trackings;
    selectTracking: ({
      trackerId,
      callBack,
    }: {
      trackerId?: string;
      callBack?: StoreStateCallback;
    }) => void;
    updateTracking: ({
      trackerId,
      pagination,
      callBack,
    }: {
      trackerId: string;
      pagination?: Pagination;
      callBack?: StoreStateCallback;
    }) => Promise<void>;
  };
}

export type TrackerSlice = (
  set: StoreApi<TrackerState>["setState"],
  get: StoreApi<TrackerState>["getState"],
  trackings: Trackings,
) => TrackerState;

export const createTrackerSlice: TrackerSlice = (set, get, trackings) => ({
  trackerSlice: {
    selectedTracking: trackings.length > 0 ? trackings[0] : undefined,
    isLoadingTrackings: false,
    trackings: trackings,
    selectTracking: ({
      trackerId,
      callBack,
    }: {
      trackerId?: string;
      callBack?: StoreStateCallback;
    }): void => {
      if (trackerId) {
        get().trackerSlice.updateTracking({ trackerId, callBack });
      } else {
        set(
          produce<TrackerState>((state) => {
            state.trackerSlice.selectedTracking = undefined;
          }),
        );
        callBack && callBack({});
      }
    },
    updateTracking: async ({
      trackerId,
      pagination,
      callBack,
    }: {
      trackerId?: string;
      pagination?: Pagination;
      callBack?: StoreStateCallback;
    }): Promise<void> => {
      set(
        produce<TrackerState>((state) => {
          state.trackerSlice.isLoadingTrackings = true;
        }),
      );

      try {
        const { trackings, error } = await getTrackings({
          trackerId,
          pagination,
        });

        if (!error && trackings[0]) {
          set(
            produce<TrackerState>((state) => {
              state.trackerSlice.selectedTracking = trackings[0];
              state.trackerSlice.isLoadingTrackings = false;
            }),
          );
        } else {
          set(
            produce<TrackerState>((state) => {
              state.trackerSlice.isLoadingTrackings = false;
            }),
          );
        }
        callBack && callBack({ error });
      } catch {
        set(
          produce<TrackerState>((state) => {
            state.trackerSlice.isLoadingTrackings = false;
          }),
        );
        callBack && callBack({ error: true });
      }
    },
  },
});
