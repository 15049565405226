"use client";

import { useState } from "react";

import { LoaderCircle, LogOut } from "lucide-react";
import { signOut } from "next-auth/react";
import { useTranslations } from "next-intl";

import { Button } from "@components/common/button";
import { ToastAction } from "@components/common/toast";
import { useToast } from "@hooks/use-toast";

const SignOut = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const t = useTranslations("common");
  const { toast } = useToast();

  const signOutHandler = (): void => {
    setIsLoading(true);
    signOut().catch(() => {
      toast({
        title: t("toast.errorTitle"),
        description: t("toast.errorDescription"),
        variant: "destructive",
        action: (
          <ToastAction
            altText={t("toast.tryAgain")}
            onClick={() => signOutHandler()}
          >
            {t("toast.tryAgain")}
          </ToastAction>
        ),
      });
      setIsLoading(false);
    });
  };

  return (
    <Button
      className={"w-full space-x-2"}
      variant={"secondary"}
      onClick={signOutHandler}
      disabled={isLoading}
    >
      {isLoading && <LoaderCircle className="mr-1 animate-spin" />}
      {!isLoading && <LogOut className="w-5 rotate-180" />}
      <span>{t("signOut.title")}</span>
    </Button>
  );
};

export default SignOut;
